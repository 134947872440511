// extracted by mini-css-extract-plugin
export var contactImage = "contact-module--contactImage--1Kb9O";
export var contactTextContainer = "contact-module--contactTextContainer--Kxqk9";
export var contactCardContainer = "contact-module--contactCardContainer--lv5EM";
export var contactCard = "contact-module--contactCard--14ivt";
export var contactCardTitle = "contact-module--contactCardTitle--2d8bj";
export var contactCardText = "contact-module--contactCardText--1Hf0x";
export var contactCardNumber = "contact-module--contactCardNumber--327VF";
export var contactLocation = "contact-module--contactLocation--3lE8x";
export var contactLocationTitle = "contact-module--contactLocationTitle--37MdH";
export var contactLocationSubtitle = "contact-module--contactLocationSubtitle--BT3rh";
export var contactLocationMapContainer = "contact-module--contactLocationMapContainer--1m3Dw";
export var contactLocationMapText = "contact-module--contactLocationMapText--3QOhP";