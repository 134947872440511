import React, { useEffect } from "react"
import AOS from "aos"

import "aos/dist/aos.css"

import { Layout, Image, TextContainer } from "../components"

import * as styles from "../styles/contact.module.scss"

export default function Contact() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    })
  }, [])
  return (
    <Layout theme="dark">
      <div style={{ overflow: "hidden" }}>
        <div className={styles.contactImage}>
          <Image src="contact-bg.jpg" alt="contact-bg"></Image>
        </div>
        <TextContainer
          className={styles.contactTextContainer}
          title="Get In Touch"
          text="We'd be happy to answer any queries you may have. Here's how you can reach us."
        ></TextContainer>
        <div data-aos="fade-up" className={styles.contactCardContainer}>
          <div className={styles.contactCard}>
            <h3 className={styles.contactCardTitle}>Call Us</h3>
            <p className={styles.contactCardText}>
              Interested in our accounting services? Just pick up the phone and
              we can advise how we can best support your company.
            </p>
            <br></br>
            <span className={styles.contactCardNumber}>+65 68448626</span>
          </div>
          <div className={styles.contactCard}>
            <h3 className={styles.contactCardTitle}>Write to Us</h3>
            <p className={styles.contactCardText}>
              Drop us a message and we'll get back to you within 1-2 working
              days.
            </p>
            <br></br>
            <span className={styles.contactCardNumber}>admin@sbtan.com</span>
          </div>
        </div>

        <div className={styles.contactLocation} data-aos="fade-up">
          <h3 className={styles.contactLocationTitle}>Visit Us</h3>
          <span className={styles.contactLocationSubtitle}>
            Note: Due to COVID-19 restrictions, please inform us beforehand if
            you are visiting our office.
          </span>
          <div className={styles.contactLocationMapContainer}>
            <iframe
              loading="lazy"
              allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_SS9kiMY2jERdFlNHDQ64qA&key=AIzaSyD1_lZ_gorm2lCXJc72OD0_DouzsVGQk78"
            ></iframe>
            <div className={styles.contactLocationMapText}>
              <h4>Office</h4>
              <span>118 Aljunied Avenue 2</span>
              <br></br>
              <span>#06-104</span>
              <br></br>
              <span>Singapore 380118</span>
              <br></br>
              <h4>Phone</h4>
              <span>+65 68448626</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
